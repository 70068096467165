import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

const srcset = (image, size, rows = 1, cols = 1) => {
	return {
		src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
		srcSet: `${image}?w=${size * cols}&h=${
			size * rows
		}&fit=crop&auto=format&dpr=2 2x`,
	};
};

const QuiltedImageList = () => {
	const data = useStaticQuery(graphql`
		query QuiltQuery {
			allMarkdownRemark(
				filter: { frontmatter: { templateKey: { eq: "product-page" } } }
			) {
				edges {
					node {
						frontmatter {
							title
							heading
							description
							intro {
								blurbs {
									image {
										childImageSharp {
											gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
										}
									}
									text
								}
								heading
								description
							}
							main {
								heading
								description
								image1 {
									alt
									image {
										childImageSharp {
											gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
										}
									}
								}
								image2 {
									alt
									image {
										childImageSharp {
											gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
										}
									}
								}
								image3 {
									alt
									image {
										childImageSharp {
											gatsbyImageData(quality: 72, layout: FULL_WIDTH)
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const { edges: itemData } = data.allMarkdownRemark;

	return (
		<Box className='container'>
			<Typography
				my={2}
				component='h5'
				variant='h5'
				color='primary'
				align='center'
				data-scroll-reveal='enter bottom move 10px over 1s after 0.2s'
			>
				What's your Gig?
			</Typography>
			<ImageList sx={{ m: 0 }} variant='quilted' cols={4} rowHeight={200}>
				{itemData.map(({ node: item }) => (
					<ImageListItem
						key={item.frontmatter.intro.blurbs.heading}
						cols={item.cols || 1}
						rows={item.rows || 1}
					>
						<img
							{...srcset(item.img, 121, item.rows, item.cols)}
							alt={item.title}
							loading='lazy'
						/>
						<ImageListItemBar
							sx={{
								background:
									'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
									'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
							}}
							title={item.frontmatter.intro.blurbs.heading}
							// position='top'
							actionIcon={
								<IconButton sx={{ color: 'white' }} aria-label={`star ${item.title}`}>
									<StarBorderIcon />
								</IconButton>
							}
							actionPosition='left'
						/>
					</ImageListItem>
				))}
			</ImageList>
		</Box>
	);
};

export default QuiltedImageList;
